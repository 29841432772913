import React, { useState, useEffect } from "react";
import axios from "axios";
import api_url, { media_url } from "../constants";
import { Link, useSearchParams } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LoadingSpinner from "../components/LoadingSpinner";
import FadeIn from "../components/FadeIn";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../store";
import { useContext } from "react";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const { language, setLanguage } = useContext(LanguageContext);

  let [args] = useSearchParams();
  let lang = args.get("lang");
  if (lang == null) {
    lang = "ro";
  }

  useEffect(() => {
    setLanguage(lang);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(api_url + "/categories/?lang=" + language)
      .then((res) => {
        setCategories(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Stil Darlean |{" "}
          {language == "ro" ? "Categorii și Servicii" : "Категории и Услуги"}
        </title>
        <link rel="canonical" href="stildarlean.md" />
        <meta
          name="description"
          content="Alege din serviciile propuse de Stil Darlean. De la pavaje - la gresie sau garduri, avem toate modelele de care ai nevoie."
        />
      </Helmet>
      {loading ? (
        <div className="pt-40 mx-auto w-fit">
          <LoadingSpinner absolute={false} />
        </div>
      ) : (
        <div className="pt-40 mx-56 lg:mx-16 xl:mx-36 md:mx-16 sm:mx-4 overflow-y-hidden">
          <p className="flex items-center gap-x-4 sm:mx-auto w-fit">
            <span className="flex gap-x-1 items-center">
              <HomeOutlinedIcon style={{ width: "1.2rem" }} />
              {language === "ro" ? "Home" : "Главная"}
            </span>
            <ArrowForwardIosIcon style={{ width: "1rem" }} />
            <span>
              {language == "ro"
                ? "Categorii produse și servicii"
                : "Категории продуктов и услуг"}
            </span>
          </p>
          <div className="grid grid-cols-3 md:gap-x-10 md:grid-cols-2 sm:grid-cols-1 mt-4 overflow-hidden py-4 px-6 gap-x-20 gap-y-12">
            {categories.map((category, index) => (
              <FadeIn index={index}>
                <Link
                  to={
                    "/categories/" + category.slug + "?page=1&lang=" + language
                  }
                  className="flex flex-col group"
                >
                  <img
                    className="rounded-xl shadow-lg group-hover:shadow-2xl group-hover:scale-[102%] transition-all ease-in-out duration-300 w-full"
                    src={media_url + category.image}
                  />
                  <h2 className="text-lg md:text-base font-semibold mt-2 transition-all ease-in-out duration-300 group-hover:mt-3">
                    {language == "ro" ? category.name : category.ru_name}
                  </h2>
                </Link>
              </FadeIn>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Categories;
