import React, { useState, useEffect } from "react";
import axios from "axios";
import api_url from "../constants";
import { Link, useSearchParams, useParams } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { motion } from "framer-motion";
import ProductCard from "../components/ProductCard";
import LoadingSpinner from "../components/LoadingSpinner";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import { LanguageContext } from "../store";

const Category = () => {
  const [products, setProducts] = useState([]);
  const [productsCount, setProductsCount] = useState([]);
  const [category, setCategory] = useState({});
  const [expandDescription, setExpandDescription] = useState(false);
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const [loading, setLoading] = useState(true);

  const { language, setLanguage } = useContext(LanguageContext);

  let [args] = useSearchParams();
  let lang = args.get("lang");
  if (lang == null) {
    lang = "ro";
  }

  useEffect(() => {
    setLanguage(lang);
  }, []);

  useEffect(() => {
    axios
      .get(api_url + "/category/" + slug + "/")
      .then((res) => {
        setCategory(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get(api_url + "/products-count/" + slug + "/")
      .then((res) => {
        const count = res.data;
        const product_pages = [];
        for (let i = 0; i < Math.ceil(count / 25); i++) {
          product_pages.push(i);
          setProductsCount(product_pages);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(api_url + "/products/" + slug + "/?limit=25&page=" + page)
      .then((res) => {
        setProducts(res.data);
        setLoading(false);
      });
  }, [page]);

  return (
    <div className="pt-40 mx-56 lg:mx-16 xl:mx-36 md:mx-16 sm:mx-4">
      <Helmet>
        <title>
          Stil Darlean |{" "}
          {category.name
            ? language == "ro"
              ? category.name
              : category.ru_name
            : language == "ro"
            ? "Vizualizare categorie"
            : "Просмотр категории"}
        </title>
        <link rel="canonical" href="stildarlean.md" />
        <meta
          name="description"
          content={`Vizualizează toate produsele de la categoria ${
            language == "ro" ? category.abbreviation : category.ru_abbreviation
          }. Alege ce ți se potrivește și prin telefon.`}
        />
      </Helmet>
      <p className="flex items-center gap-x-4 flex-wrap md:mx-auto w-fit">
        <span className="flex gap-x-1 items-center ">
          <HomeOutlinedIcon style={{ width: "1.2rem" }} />
          {language == "ro" ? "Home" : "Главная"}
        </span>
        <ArrowForwardIosIcon style={{ width: "1rem" }} />
        <span>
          {language == "ro"
            ? "Categorii produse și servicii"
            : "Категории продуктов и услуг"}
        </span>
        <ArrowForwardIosIcon style={{ width: "1rem" }} />
        <span>{language == "ro" ? category.name : category.ru_name}</span>
      </p>
      <h1 className="mt-4 text-xl font-medium">
        {language == "ro" ? category.name : category.ru_name} -{" "}
        {language == "ro" ? "Modele" : "Модели"}
      </h1>
      <motion.div
        initial={{ height: "3rem" }}
        animate={expandDescription ? { height: "auto" } : { height: "3rem" }}
        className="overflow-y-hidden scrollbar-none mt-2 flex flex-col gap-y-1"
      >
        {category.description &&
          (language == "ro" ? category.description : category.ru_description)
            .split("\n")
            .map((item, i) => <p key={i}>{item}</p>)}
      </motion.div>
      {category.description &&
        category.description.length > 350 &&
        !expandDescription && (
          <div
            onClick={() => setExpandDescription(true)}
            className="text-sm w-fit ml-auto font-semibold cursor-pointer mt-1"
          >
            {language == "ro" ? "Afișează restul" : "Показать остальное"}
          </div>
        )}

      <div>
        <div className="flex gap-x-4 mt-4">
          {page > 4 && (
            <Link
              to={"/categories/" + slug + "?page=1&lang=" + language}
              className={
                "w-8 h-8 flex items-center justify-center rounded-full " +
                (page == 1
                  ? "bg-primary text-white"
                  : "bg-gray-200 text-gray-500")
              }
            >
              1
            </Link>
          )}
          {page > 4 && (
            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 text-gray-500">
              ...
            </div>
          )}

          {(productsCount.length > 5 && (
            <div className="flex gap-x-4">
              {productsCount
                .slice(page > 4 ? page - 3 : 0, page > 4 ? Number(page) + 2 : 5)
                .map((product_count) => (
                  <Link
                    to={"/categories/" + slug + "?page=" + (product_count + 1)}
                    className={
                      "w-8 h-8 flex items-center justify-center rounded-full " +
                      (page == product_count + 1
                        ? "bg-primary text-white"
                        : "bg-gray-200 text-gray-500")
                    }
                  >
                    {product_count + 1}
                  </Link>
                ))}
              {page < productsCount.length - 2 ? "..." : ""}
              {page < productsCount.length - 2 && (
                <Link
                  to={
                    "/categories/" +
                    slug +
                    "?page=" +
                    productsCount.length +
                    "&lang=" +
                    language
                  }
                  className={
                    "w-8 h-8 flex items-center justify-center rounded-full " +
                    (page == productsCount.length
                      ? "bg-primary text-white"
                      : "bg-gray-200 text-gray-500")
                  }
                >
                  {productsCount.length}
                </Link>
              )}
            </div>
          )) ||
            productsCount.map((product_count) => (
              <Link
                to={
                  "/categories/" +
                  slug +
                  "?page=" +
                  (product_count + 1) +
                  "&lang=" +
                  language
                }
                className={
                  "w-8 h-8 flex items-center justify-center rounded-full " +
                  (page == product_count + 1
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-500")
                }
              >
                {product_count + 1}
              </Link>
            ))}
        </div>
        {loading ? (
          <div className="pt-20 mx-auto w-fit">
            <LoadingSpinner absolute={false} />
          </div>
        ) : (
          <div className="grid grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 lg:gap-x-14 md:gap-x-10 sm:gap-x-8 mt-8 gap-x-20 gap-y-12 overflow-hidden">
            {products.map((product, index) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.1 * index }}
              >
                <ProductCard type="big" product={product} />
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Category;
