import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import api_url, { media_url } from "../constants";
import { useParams, useSearchParams } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
import useMediaQuery from "../hooks/useMediaQuery";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../store";
const Product = () => {
  const { slug } = useParams();

  const [product, setProduct] = useState({});
  const [mainImage, setMainImage] = useState(null);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productPages, setProductPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const isLG = useMediaQuery("(min-width: 1024px)");
  const isXL = useMediaQuery("(min-width: 1280px)");
  const isMD = useMediaQuery("(min-width: 641px)");
  const isSM = useMediaQuery("(max-width: 640px)");
  useEffect(() => {
    const images = document.querySelectorAll("img");
    images.forEach((image) => {
      image.addEventListener("dragstart", (e) => e.preventDefault());
      image.setAttribute("draggable", false);
    });

    axios
      .get(`${api_url}/product/${slug}/`)
      .then((res) => {
        setProduct(res.data);
        setMainImage(res.data.image_1);
        const category = res.data.category.slug;

        axios.get(`${api_url}/recommended-products/`).then((res) => {
          const res_products = res.data;
          setProducts(res.data);
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    //get products from this category
  }, [slug]);

  const { language, setLanguage } = useContext(LanguageContext);

  let [args] = useSearchParams();
  let lang = args.get("lang");
  if (lang == null) {
    lang = "ro";
  }

  useEffect(() => {
    if (products.length == 0) return;
    const pages = [];
    if (isSM) {
      for (let i = 0; i < Math.ceil(products.length / 3); i++) {
        pages.push(i);
      }
    }
    if ((isLG && !isXL) || (isMD && !isLG)) {
      for (let i = 0; i < Math.ceil(products.length / 4); i++) {
        pages.push(i);
      }
    }
    if (isXL || (isLG && !isMD)) {
      for (let i = 0; i < Math.ceil(products.length / 5); i++) {
        pages.push(i);
      }
    }

    setProductPages(pages);
  }, [products, isSM, isLG, isXL, isMD]);

  useEffect(() => {
    if (loading) return;
    const carousel = document.getElementById("recommendetations-carousel");
    carousel.scrollLeft = (currentPage - 1) * carousel.clientWidth;
  }, [currentPage]);
  useEffect(() => {
    setLanguage(lang);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Stil Darlean |{" "}
          {product.name
            ? language == "ro"
              ? product.name
              : product.ru_name
            : language == "ro"
            ? "Vizualizare produs"
            : "Просмотр продукта"}
        </title>
        <link rel="canonical" href="stildarlean.md" />
        <meta
          name="description"
          content={
            product.description &&
            (language == "ro"
              ? product.description.slice(0, product.description.indexOf("\n"))
              : product.ru_description.slice(
                  0,
                  product.ru_description.indexOf("\n")
                ))
          }
        />
      </Helmet>
      {loading ? (
        <div className="pt-40 mx-auto w-fit">
          <LoadingSpinner absolute={false} />
        </div>
      ) : (
        <div className="pt-40 mx-56 lg:mx-16 xl:mx-36 md:mx-16 sm:mx-4">
          <p className="flex items-center gap-x-4 flex-wrap md:mx-auto sm:mx-auto w-fit">
            <span className="flex gap-x-1 items-center">
              <HomeOutlinedIcon style={{ width: "1.2rem" }} />
              {language == "ro" ? "Home" : "Главная"}
            </span>
            <ArrowForwardIosIcon style={{ width: "1rem" }} />
            <span>
              {language == "ro"
                ? "Categorii produse și servicii"
                : "Категории продуктов и услуг"}
            </span>
            <ArrowForwardIosIcon style={{ width: "1rem" }} />
            <span>
              {product.category &&
                (language == "ro"
                  ? product.category.name
                  : product.category.ru_name)}
            </span>
            <ArrowForwardIosIcon style={{ width: "1rem" }} />
            <span>{language == "ro" ? product.name : product.ru_name}</span>
          </p>
          <h2 className="text-2xl lg:hidden xl:hidden 2xl:hidden text-center mt-4 font-semibold">
            {language == "ro" ? product.name : product.ru_name}
          </h2>
          <h3 className="text-primary  lg:hidden xl:hidden text-center 2xl:hidden ">
            {language == "ro" ? product.subtitle : product.ru_subtitle}
          </h3>
          <div className="mt-2 flex gap-x-8 md:flex-col gap-y-8 sm:flex-col">
            <div className="w-96  sm:w-full md:mx-auto sm:mx-auto">
              <img
                className="w-96 h-fit sm:w-full rounded-xl border-2 border-solid border-black"
                src={media_url + mainImage}
              />
              <div className="w-full grid grid-cols-3 gap-x-4 mt-2 justify-between">
                <img
                  onClick={() => {
                    setMainImage(product.image_1);
                  }}
                  className="rounded-xl border-2 border-solid border-black"
                  src={media_url + product.image_1}
                />
                <img
                  onClick={() => setMainImage(product.image_2)}
                  className="rounded-xl border-2 border-solid border-black"
                  src={media_url + product.image_2}
                />

                <img
                  onClick={() => setMainImage(product.image_3)}
                  className="rounded-xl border-2 border-solid border-black"
                  src={media_url + product.image_3}
                />
              </div>
            </div>
            <div className="w-[calc(100%-24rem)] md:w-full sm:w-full flex flex-col">
              <div className="flex gap-x-6 items-center">
                <h1 className="text-2xl font-semibold md:hidden sm:hidden">
                  {language == "ro" ? product.name : product.ru_name}
                </h1>
                {product.price != 0 && (
                  <div className="bg-red-500 px-8 font-semibold text-lg text-white w-fit">
                    {language == "ro" ? "De la " : "От "}
                    {product.price}
                    {language == "ro" ? " lei" : " лей"}
                  </div>
                )}
              </div>

              <h3 className="text-primary md:hidden sm:hidden">
                {language == "ro" ? product.subtitle : product.ru_subtitle}
              </h3>
              <div className="gap-y-1  flex flex-col">
                {product.description &&
                  (language == "ro"
                    ? product.description
                    : product.ru_description
                  )
                    .split("\n")
                    .map((item, i) => <p key={i}>{item}</p>)}
              </div>
              <div className="mt-3 flex flex-col gap-y-2">
                {product.weight > 0 && (
                  <div>
                    <span className="font-semibold">
                      {language == "ro" ? "Greutate:" : "Вес:"}
                    </span>{" "}
                    {product.weight} {language == "ro" ? "kg" : "кг"}
                  </div>
                )}
                {product.thickness > 0 && (
                  <div>
                    <span className="font-semibold">
                      {language == "ro" ? "Grosime:" : "Толщина:"}
                    </span>{" "}
                    {product.thickness} {language == "ro" ? "mm" : "мм"}
                  </div>
                )}
                {product.variants && product.variants.length > 0 && (
                  <div>
                    <span className="font-semibold">
                      {language == "ro"
                        ? "Variantele propuse:"
                        : "Предлагаемые варианты:"}
                    </span>{" "}
                    {language == "ro"
                      ? product.variants
                          .split("\n")
                          .map((item, i) => <p key={i}>{item}</p>)
                      : product.ru_variants
                          .split("\n")
                          .map((item, i) => <p key={i}>{item}</p>)}
                  </div>
                )}
              </div>
              <a
                href="tel:+37369097890"
                className="bg-buttonGray text-center mt-auto block w-fit md:mt-8 sm:mt-8 md:ml-auto sm:w-full font-medium text-primary text-xl italic py-2 px-10"
              >
                {language == "ro" ? "Sunați-ne acum!" : "Позвоните нам сейчас!"}
              </a>
            </div>
          </div>
          <div className="mt-20">
            <h2 className="text-lg font-semibold">
              {language == "ro"
                ? "S-ar putea să te intereseze și"
                : "Вам может быть интересно"}
            </h2>
            <div className="w-[105%] translate-x-[-2.5%] px-8 relative">
              <button
                onClick={() => {
                  setCurrentPage(
                    currentPage == 1 ? productPages.length : currentPage - 1
                  );
                }}
                className="transition-colors ease-in-out duration-300 hover:bg-black/30 p-3 absolute top-1/2 left-0 translate-y-[-50%] translate-x-[-75%] w-fit rounded-full bg-black/20"
              >
                <ArrowForwardIosIcon className="w-4 sm:w-3 opacity-40 rotate-180 " />
              </button>
              <button
                onClick={() => {
                  setCurrentPage(
                    currentPage == productPages.length ? 1 : currentPage + 1
                  );
                }}
                className="transition-colors ease-in-out duration-300 hover:bg-black/30 p-3 absolute top-1/2 right-0 trnslate-y-[-50%] translate-x-[75%] w-fit rounded-full bg-black/20"
              >
                <ArrowForwardIosIcon className="w-4 sm:w-3 opacity-40" />
              </button>
              <div
                className="w-full mt-4 overflow-auto scrollbar-none "
                id="recommendetations-carousel"
              >
                <div
                  style={{
                    width: productPages.length * 100 + "%",
                  }}
                  className={`justify-between  flex flex-nowrap gap-x-10`}
                >
                  {products.map((product) => (
                    <Link
                      to={"/products/" + product.slug + "/?lang=" + lang}
                      className="flex flex-col w-44 md:w-80 group"
                    >
                      <img
                        className="w-44 drop-shadow-md md:w-80 group-hover:scale-[102%] transition-all ease-in-out duration-300 "
                        src={media_url + product.image_1}
                      />
                      <h3 className="text-xs text-center font-medium mt-2">
                        {product.name}
                      </h3>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Product;
