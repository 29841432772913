import React, { useContext } from "react";
import api_url, { media_url } from "../constants";
import { Link, useSearchParams } from "react-router-dom";
import { LanguageContext } from "../store";
const ProductCard = ({ product, type = "small" }) => {
  const { language, setLanguage } = useContext(LanguageContext);

  let [args] = useSearchParams();
  let lang = args.get("lang");
  if (lang == null) {
    lang = "ro";
  }

  return (
    <Link
      to={"/products/" + product.slug + "/?lang=" + lang}
      className="flex group flex-col w-fit mx-auto p-2"
    >
      <div className="relative w-fit group-hover:scale-[102%] ease-in-out duration-300 transition-all">
        <img
          className={
            "  h-fit transition-all ease-in-out duration-300 rounded-2xl object-cover mx-auto  group-hover:shadow-2xl shadow-lg" +
            (type == "small" ? "w-2/3" : "w-full")
          }
          src={media_url + product.image_1}
        />
        {product.at_promotion && type != "small" && (
          <div className="bg-red-500 px-2 rounded-tl-2xl py-0.5 absolute top-0 left-0 text-white font-medium">
            {language == "ro" ? "De la " : "От "}
            {product.price} {language == "ro" ? "lei!" : "лей!"}
          </div>
        )}
      </div>
      <h3 className="w-fit text-sm mx-auto mt-2 font-medium text-center group-hover:translate-y-1 transition-all ease-in-out duration-300">
        {language == "ro" ? product.name : product.name_ru}
      </h3>
    </Link>
  );
};

export default ProductCard;
