import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../store";
const Contacts = () => {
  const { language, setLanguage } = useContext(LanguageContext);

  let [args] = useSearchParams();
  let lang = args.get("lang");
  if (lang == null) {
    lang = "ro";
  }

  useEffect(() => {
    setLanguage(lang);
  }, []);
  return (
    <div className="pt-40 mx-56 lg:mx-16 xl:mx-36 md:mx-16 sm:mx-4">
      <Helmet>
        <title>
          Stil Darlean | {language == "ro" ? "Contacte" : "Контакты"}
        </title>
        <link rel="canonical" href="stildarlean.md" />
        <meta
          name="description"
          content="Bun venit pe pagina noastră de contact! Suntem încântați să auzim de la tine și să răspundem la orice întrebări sau preocupări ai putea avea."
        />
      </Helmet>
      <div className="bg-backgroundGray py-6 px-10 w-full">
        <h1 className="text-xl font-semibold uppercase">
          {language == "ro" ? "Despre noi" : "О нас"}
        </h1>
        <div className="flex flex-col gap-y-3 mt-2">
          <p>
            {language == "ro"
              ? "Bine ați venit la Stil Darlean, lideri în domeniul nostru de activitate încă din 2018. Cu o experiență solidă și o abordare inovatoare, ne-am dedicat să oferim servicii de calitate superioară și soluții personalizate clienților noștri exigenți."
              : "Добро пожаловать в Stil Darlean, лидеры в нашей отрасли с 2018 года. Солидный опыт и инновационный подход позволили нам посвятить себя предоставлению высококачественных услуг и индивидуальных решений нашим требовательным клиентам."}
          </p>
          <h2 className="font-semibold text-lg">
            {language == "ro"
              ? "De ce să ne alegi pe noi?"
              : "Почему выбрать нас?"}
          </h2>
          <p>
            {language == "ro"
              ? "Avem mândria de a oferi servicii de cea mai înaltă calitate în domeniul nostru, iar rezultatele noastre vorbesc de la sine. Cu peste 530 de clienți satisfăcuți, ne-am câștigat reputația de partener de încredere în proiecte variate, de la amenajări exterioare la soluții de design interior."
              : "Мы гордимся тем, что предлагаем услуги самого высокого качества в нашей отрасли, и наши результаты говорят сами за себя. С более чем 530 довольными клиентами мы завоевали репутацию надежного партнера в различных проектах, от внешней отделки до решений для интерьера."}
          </p>
          <h2 className="font-semibold text-lg">
            {language == "ro" ? "Ce ne diferențiază?" : "Что нас отличает?"}
          </h2>
          <ol className="list-decimal list-inside">
            <li>
              <strong>
                {language == "ro" ? "Experiență Solidă:" : "Солидный опыт:"}
              </strong>{" "}
              {language == "ro"
                ? "Cu o prezență în industrie încă din 2018, am acumulat cunoștințe și expertiză, asigurându-ne că suntem mereu în pas cu cele mai recente tehnologii și tendințe"
                : "С нашим присутствием в отрасли с 2018 года мы накопили знания и экспертизу, убедившись, что всегда следуем за последними технологиями и тенденциями."}
            </li>
            <li>
              <strong>
                {language == "ro"
                  ? "Angajament față de Calitate:"
                  : "Обязательство к качеству:"}
              </strong>{" "}
              {language == "ro"
                ? " Calitatea este fundamentul activității noastre. Folosim materiale de înaltă calitate și tehnici avansate pentru a livra proiecte durabile și estetice."
                : "Качество является основой нашей деятельности. Мы используем высококачественные материалы и передовые технологии, чтобы создавать долговечные и эстетические проекты."}
            </li>
            <li>
              <strong>
                {language == "ro"
                  ? "Soluții Personalizate:"
                  : "Индивидуальные решения:"}
              </strong>{" "}
              {language == "ro"
                ? "Înțelegem că fiecare proiect este unic. Echipa noastră dedicată lucrează îndeaproape cu clienții pentru a dezvolta soluții personalizate, adaptate cerințelor și gusturilor individuale."
                : "Мы понимаем, что каждый проект уникален. Наша преданная команда тесно сотрудничает с клиентами, чтобы разработать индивидуальные решения, адаптированные к индивидуальным требованиям и вкусам."}
            </li>
            <li>
              <strong>
                {language == "ro"
                  ? "530+ Clienți Satisfăcuți:"
                  : "530+ Довольных клиентов:"}
              </strong>{" "}
              {language == "ro"
                ? "Recunoașterea noastră este reflectată în mulțimea de clienți mulțumiți pe care i-am servit de-a lungul anilor. Feedback-ul pozitiv și recomandările clienților reprezintă certificarea calității și profesionalismului nostru."
                : "Наше признание отражается во множестве довольных клиентов, которых мы обслуживали на протяжении многих лет. Положительная обратная связь и рекомендации клиентов являются подтверждением нашего качества и профессионализма."}
            </li>
          </ol>
        </div>
      </div>
      <div className="flex justify-between mt-8">
        <form className="bg-backgroundGray px-12 py-6 w-1/2 md:w-3/4 sm:w-full flex-col gap-y-4 flex">
          <p className="black text-xl font-semibold">
            {language == "ro"
              ? "Formular de contact. Vă vom răspunde în cel mai scurt timp posibil."
              : "Контактная форма. Мы ответим вам как можно скорее."}
          </p>
          <div className="mt-8 flex flex-col gap-y-8">
            <input
              className="contact-input border-black text-black placeholder:text-black/75 font-medium"
              placeholder={language == "ro" ? "Nume" : "Имя"}
            />
            <input
              className="contact-input border-black text-black placeholder:text-black/75 font-medium"
              placeholder={language == "ro" ? "Telefon" : "Телефон"}
            />
            <textarea
              minLength={10}
              className="contact-input border-black text-black placeholder:text-black/75 font-medium"
              placeholder={language == "ro" ? "Mesaj" : "Сообщение"}
            ></textarea>
          </div>
          <button className="text-primary px-8 py-4 text-lg font-medium mt-auto bg-buttonGray w-full">
            {language == "ro" ? "Trimite" : "Отправить"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contacts;
