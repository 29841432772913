import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import api_url, { media_url } from "../constants";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import LoadingSpinner from "../components/LoadingSpinner";
const AdminProduct = () => {
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${api_url}/product/${id}/`)
      .then((response) => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    (loading && <LoadingSpinner />) || (
      <div className="">
        <div className="mt-2">
          <Link
            to={"/admin/products/" + product.id + "/edit"}
            className=" admin-primary-button px-8 text-sm ml-auto my-4 xl:hidden lg:hidden 2xl:hidden block w-fit"
          >
            Editează produsul
          </Link>
          <p className="flex items-center gap-x-4 flex-wrap gap-y-1 sm:text-sm md:text-sm md:mx-auto w-fit">
            <span className="flex gap-x-1 items-center">
              <HomeOutlinedIcon style={{ width: "1.2rem" }} />
              Home
            </span>
            <ArrowForwardIosIcon style={{ width: "1rem" }} />
            <span>Categorii produse și servicii</span>
            <ArrowForwardIosIcon style={{ width: "1rem" }} />
            <span>{product.category && product.category.name}</span>
          </p>
          <h2 className="text-2xl lg:hidden xl:hidden 2xl:hidden text-center mt-4 font-semibold">
            {" "}
            {product.name}
          </h2>
          <h3 className="text-primary  lg:hidden xl:hidden text-center 2xl:hidden ">
            {product.subtitle}
          </h3>
          <div className="mt-2 flex gap-x-8 md:flex-col sm:flex-col gap-y-6">
            <div className="w-96 sm:w-full md:mx-auto sm:mx-auto">
              <img
                className="w-96 sm:w-full sm:h-auto h-96 rounded-xl border-2 border-solid border-black"
                src={media_url + product.image_1}
              />
              <div className="w-full grid grid-cols-3 gap-x-4 sm:gap-x-2 mt-2 justify-between">
                <img
                  className="rounded-xl border-2 border-solid border-black"
                  src={media_url + product.image_1}
                />
                <img
                  className="rounded-xl border-2 border-solid border-black"
                  src={media_url + product.image_2}
                />

                <img
                  className="rounded-xl border-2 border-solid border-black"
                  src={media_url + product.image_3}
                />
              </div>
            </div>
            <div className="md:mr-0 sm:mr-0 md:w-full sm:w-full w-[calc(100%-24rem)] flex flex-col">
              <h2 className="text-2xl md:hidden sm:hidden font-semibold flex justify-between items-center h-fit">
                <div className="flex gap-x-6 items-center">
                  <h2 className="text-2xl md:hidden sm:hidden font-semibold">
                    {product.name}
                  </h2>
                  {product.price != 0 && (
                    <div className="bg-red-500 px-8 font-semibold text-lg text-white w-fit">
                      De la {product.price} lei
                    </div>
                  )}
                </div>
                <Link
                  to={"/admin/products/" + product.id + "/edit"}
                  className=" admin-primary-button px-8 text-sm"
                >
                  Editează produsul
                </Link>
              </h2>
              <h3 className="md:hidden sm:hidden text-primary">
                {product.subtitle}
              </h3>
              <div className="gap-y-1  flex flex-col">
                {product.description &&
                  product.description
                    .split("\n")
                    .map((item, i) => <p key={i}>{item}</p>)}
              </div>
              <div className="mt-3 flex flex-col gap-y-2">
                {product.weight > 0 && (
                  <div>
                    <span className="font-semibold">Greutate:</span>{" "}
                    {product.weight} kg
                  </div>
                )}
                {product.thickness > 0 && (
                  <div>
                    <span className="font-semibold">Grosime:</span>{" "}
                    {product.thickness} mm
                  </div>
                )}
                {product.variants && product.variants.length > 0 && (
                  <div>
                    <span className="font-semibold">Variantele propuse:</span>{" "}
                    {product.variants.split("\n").map((item, i) => (
                      <p key={i}>{item}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default AdminProduct;
