import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./screens/Home";
import AdminLayout from "./screens/AdminLayout";
import AdminProducts from "./screens/AdminProducts";
import AdminAddProduct from "./screens/AdminAddProduct";
import AdminCategories from "./screens/AdminCategories";
import AdminAddCategory from "./screens/AdminAddCategory";
import AdminEditCategory from "./screens/AdminEditCategory";
import AdminProduct from "./screens/AdminProduct";
import AdminPromotions from "./screens/AdminPromotions";
import Categories from "./screens/Categories";
import UserLayout from "./screens/UserLayout";
import Category from "./screens/Category";
import Product from "./screens/Product";
import AdminProductEdit from "./screens/AdminProductEdit";
import Contacts from "./screens/Contacts";
import { LanguageContext } from "./store";

function App() {
  let local_language = localStorage.getItem("language");
  const [language, setLanguage] = useState(
    local_language !== null ? local_language : "ro"
  );
  const navigate = useNavigate();

  useEffect(() => {
    navigate("?lang=" + language);
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <div className="min-h-[100vh] flex-col flex bg-background relative">
        <Routes>
          <Route path={"/"} element={<UserLayout />}>
            <Route path="" element={<Home />} />
            <Route path="categories" element={<Categories />} />
            <Route path="categories/:slug" element={<Category />} />
            <Route path="products/:slug" element={<Product />} />
            <Route path="contact" element={<Contacts />} />
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="/admin/products" element={<AdminProducts />} />

            <Route path="/admin/products/add" element={<AdminAddProduct />} />
            <Route path="/admin/categories" element={<AdminCategories />} />
            <Route
              path="/admin/categories/add"
              element={<AdminAddCategory />}
            />
            <Route path="/admin/promotions" element={<AdminPromotions />} />

            <Route path="/admin/products/:id" element={<AdminProduct />} />
            <Route
              path="/admin/products/:id/edit"
              element={<AdminProductEdit />}
            />
            <Route
              path="/admin/categories/:id/edit"
              exact={true}
              element={<AdminEditCategory />}
            />
          </Route>
        </Routes>
      </div>
    </LanguageContext.Provider>
  );
}

export default App;
