import React from "react";
import { LineWave } from "react-loader-spinner";
import { motion } from "framer-motion";
const LoadingSpinner = ({ absolute = true, color = "#474747" }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={
        absolute
          ? "top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2"
          : ""
      }
    >
      <LineWave
        height="125"
        width="125"
        color={color}
        ariaLabel="line-wave"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        firstLineColor=""
        middleLineColor=""
        lastLineColor=""
      />
    </motion.div>
  );
};

export default LoadingSpinner;
