import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ProductImage from "../components/ProductImage";
import axios from "axios";
import api_url from "../constants";
import { Link, useNavigate } from "react-router-dom";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
const AdminAddProduct = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [productName, setProductName] = useState("");
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [thickness, setThickness] = useState("");
  const [weight, setWeight] = useState("");
  const [variants, setVariants] = useState("");
  const [price, setPrice] = useState("");
  const [files, setFiles] = useState([
    {
      file: null,
      fileType: null,
    },
    {
      file: null,
      fileType: null,
    },
    {
      file: null,
      fileType: null,
    },
  ]);

  useEffect(() => {
    axios.get(`${api_url}/categories/`).then((res) => {
      setCategories(res.data);
    });
  }, []);

  function CreateProduct(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("category_id", selectedCategory);
    formData.append("image_1", files[0].file);
    formData.append("image_2", files[1].file);
    formData.append("image_3", files[2].file);
    formData.append("image_1_type", files[0].fileType.split("/")[1]);
    formData.append("image_2_type", files[1].fileType.split("/")[1]);
    formData.append("image_3_type", files[2].fileType.split("/")[1]);
    axios
      .post(`${api_url}/product/`, formData)
      .then((res) => {
        navigate("/admin/products");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <form
        onSubmit={CreateProduct}
        className="flex gap-x-4 flex-wrap gap-y-6 sm:justify-center"
      >
        <div className="grid grid-cols-2 gap-4 h-fit">
          <ProductImage key={0} index={0} setFiles={setFiles} files={files} />
          <ProductImage key={1} index={1} setFiles={setFiles} files={files} />
          <ProductImage key={2} index={2} setFiles={setFiles} files={files} />
        </div>
        <div className="w-80 flex flex-col gap-y-4">
          <input
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            name="name"
            className="admin-input w-full"
            placeholder="*Nume produs"
          />
          <input
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            name="keywords"
            className="admin-input w-full"
            placeholder="*Cuvinte cheie (separate prin virgulă)"
          />
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="admin-input w-full min-h-[14.6rem]"
            placeholder="*Descriere produs"
            name="description"
          ></textarea>
          <select
            className="admin-input py-1.5"
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="0" selected disabled hidden>
              *Categorie
            </option>
            {categories.map((category) => (
              <option className="py-2 px-2" value={category.id}>
                <div className="py-2">{category.name}</div>
              </option>
            ))}
          </select>
        </div>
        <div className="w-80 flex flex-col gap-y-4">
          <input
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
            name="subtitle"
            className="admin-input w-full"
            placeholder="Subtext"
          />
          <input
            value={thickness}
            onChange={(e) => setThickness(e.target.value)}
            name="thickness"
            className="admin-input w-full"
            placeholder="Grosime"
          />
          <input
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            name="weight"
            className="admin-input w-full"
            placeholder="Greutate"
          />
          <input
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            name="price"
            className="admin-input w-full"
            placeholder="Preț de start"
          />
          <textarea
            value={variants}
            onChange={(e) => setVariants(e.target.value)}
            name="variants"
            className="admin-input w-full min-h-[11.4rem]"
            placeholder="Variantele propuse"
          ></textarea>
        </div>
        <div className="flex flex-col gap-y-4">
          <Link to="/admin/products" className="admin-alert-button px-8">
            Anulează formular
          </Link>
          <button className="admin-primary-button px-8">Adaugă produs</button>
        </div>
      </form>
      <div className="mt-10">
        <h2 className="font-semibold text-lg">Previzualizare</h2>
        <div className="mt-2">
          <p className="flex items-center gap-x-4 flex-wrap gap-y-1 sm:text-sm md:text-sm md:mx-auto w-fit">
            <span className="flex gap-x-1 items-center">
              <HomeOutlinedIcon style={{ width: "1.2rem" }} />
              Home
            </span>
            <ArrowForwardIosIcon style={{ width: "1rem" }} />
            <span>Categorii produse și servicii</span>
            <ArrowForwardIosIcon style={{ width: "1rem" }} />
            <span>
              {categories.find((c) => c.id == selectedCategory)?.name}
            </span>
          </p>
          <h2 className="text-2xl lg:hidden xl:hidden 2xl:hidden text-center mt-4 font-semibold">
            {" "}
            {productName}
          </h2>
          <h3 className="text-primary lg:hidden xl:hidden text-center 2xl:hidden ">
            {subtitle}
          </h3>
          <div className="mt-2 flex gap-x-8 md:flex-col sm:flex-col gap-y-6">
            <div className="w-96 sm:w-72 md:mx-auto sm:mx-auto">
              {files[0].file ? (
                <img
                  className="w-96 h-96 rounded-xl border-2 border-solid border-black"
                  src={URL.createObjectURL(files[0].file)}
                />
              ) : (
                <div className="w-96 sm:w-72 h-96 sm:h-72 relative rounded-xl border-2 border-solid border-black">
                  <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <QuestionMarkIcon />
                  </span>
                </div>
              )}
              <div className="w-full grid grid-cols-3 gap-x-4 mt-2 justify-between">
                {files[0].file ? (
                  <img
                    className="rounded-xl border-2 border-solid border-black"
                    src={URL.createObjectURL(files[0].file)}
                  />
                ) : (
                  <div className="h-28 sm:h-20 relative rounded-xl border-2 border-solid border-black">
                    <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <QuestionMarkIcon />
                    </span>
                  </div>
                )}
                {files[1].file ? (
                  <img
                    className="rounded-xl border-2 border-solid border-black"
                    src={URL.createObjectURL(files[1].file)}
                  />
                ) : (
                  <div className="h-28 sm:h-20 relative rounded-xl border-2 border-solid border-black">
                    <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <QuestionMarkIcon />
                    </span>
                  </div>
                )}
                {files[2].file ? (
                  <img
                    className="rounded-xl border-2 border-solid border-black"
                    src={URL.createObjectURL(files[2].file)}
                  />
                ) : (
                  <div className="h-28 sm:h-20 relative rounded-xl border-2 border-solid border-black">
                    <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <QuestionMarkIcon />
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="mr-20 md:mr-0 sm:mr-0 md:w-full sm:w-full w-[calc(100%-24rem)] flex flex-col">
              <div className="flex gap-x-6 items-center">
                <h2 className="text-2xl md:hidden sm:hidden font-semibold">
                  {productName}
                </h2>
                {price != 0 && (
                  <div className="bg-red-500 px-8 font-semibold text-lg text-white w-fit">
                    De la {price} lei
                  </div>
                )}
              </div>
              <h3 className="text-primary md:hidden sm:hidden ">{subtitle}</h3>
              <div className="gap-y-1  flex flex-col">
                {description.split("\n").map((item, i) => (
                  <p key={i}>{item}</p>
                ))}
              </div>
              <div className="mt-3 flex flex-col gap-y-2">
                {weight > 0 && (
                  <div>
                    <span className="font-semibold">Greutate:</span> {weight} kg
                  </div>
                )}
                {thickness > 0 && (
                  <div>
                    <span className="font-semibold">Grosime:</span> {thickness}{" "}
                    mm
                  </div>
                )}
                {variants.length > 0 && (
                  <div>
                    <span className="font-semibold">Variantele propuse:</span>{" "}
                    {variants.split("\n").map((item, i) => (
                      <p key={i}>{item}</p>
                    ))}
                  </div>
                )}
              </div>
              {productName && description && (
                <button className="bg-buttonGray mt-auto sm:mt-8 sm:w-full text-center md:mt-8 md:ml-auto w-fit break-keep whitespace-nowrap	 font-medium text-primary text-xl italic py-2 px-10">
                  Sunați-ne acum!
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAddProduct;
