import React, { useEffect, useState, useMemo } from "react";
import { Outlet, useLocation, useSearchParams, Link } from "react-router-dom";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import { motion } from "framer-motion";
import FacebookIcon from "@mui/icons-material/Facebook";
import diagram_1 from "../static/images/diagram-1.svg";
import gravaj from "../static/images/gravaj.svg";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import api_url from "../constants";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../store";

const UserLayout = () => {
  const [currentPage, setCurrentPage] = useState("/");
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: "AIzaSyDuaTkVh8-ue6vfJSYHUbaVYIXGxjiao7g",
  // });

  const center = useMemo(() => ({ lat: 47.6469805, lng: 23.536237 }), []);
  const location = useLocation();
  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  const [expandMenu, setExpandMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  const { language, setLanguage } = useContext(LanguageContext);

  let { lang } = useParams();
  if (lang == null) {
    lang = "ro";
  }

  useEffect(() => {
    setLanguage(lang);
  }, []);

  useEffect(() => {
    axios
      .get(`${api_url}/categories/`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="flex flex-col min-h-[100vh] ">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: expandMenu ? "100%" : 0 }}
        className="bg-backgroundGray min-h-screen fixed top-0 left-0 bottom-0 right-0 z-50 overflow-x-auto"
      >
        <span
          onClick={() => setExpandMenu(false)}
          className="hidden w-fit sm:block ml-auto mt-6 mr-4 cursor-pointer"
        >
          <MenuIcon className="text-black scale-125" />
        </span>
        <div className="flex w-fit mx-auto min-w-[20rem]  flex-col gap-y-8 mt-12 ">
          <Link
            to={"/?lang=" + language}
            className="flex items-baseline"
            onClick={() => setExpandMenu(false)}
          >
            <h1 className="font-viga text-3xl text-black lg:text-2xl">
              STIL DARLEAN GRUP
            </h1>
          </Link>
          <nav className="flex text-black text-xl w-fit flex-col gap-y-6">
            <Link
              to={"/"}
              className=" relative w-fit"
              onClick={() => setExpandMenu(false)}
            >
              {language == "ro" ? "Acasă" : "Главная"}
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: currentPage == "/" ? "100%" : 0 }}
                className="bg-primary h-0.5"
              ></motion.div>
            </Link>
            <Link
              to="/categories"
              className="relative w-fit"
              onClick={() => setExpandMenu(false)}
            >
              {language == "ro" ? "Produse și servicii" : "Продукты и услуги"}
              <motion.div
                initial={{ width: 0 }}
                animate={{
                  width:
                    currentPage.includes("categorie") ||
                    currentPage.includes("products")
                      ? "100%"
                      : 0,
                }}
                className="bg-primary h-0.5"
              ></motion.div>
            </Link>
            <Link
              to={"/contact?lang=" + language}
              className=" relative w-fit"
              onClick={() => setExpandMenu(false)}
            >
              {language == "ro" ? "Contacte" : "Контакты"}
              <motion.div
                initial={{ width: 0 }}
                animate={{
                  width: currentPage.includes("contact") ? "100%" : 0,
                }}
                className="bg-primary h-0.5"
              ></motion.div>
            </Link>
          </nav>
        </div>
      </motion.div>
      <div className="z-10 absolute w-full top-0 left-0">
        <div className="bg-[#2B2B2B]/70 shadow-lg w-full md:flex-col gap-y-4 flex justify-between items-center my-auto py-2 px-4">
          <div className="flex items-center gap-x-20 w-fit  lg:gap-x-4 md:gap-x-6 md:justify-between md:w-full sm:flex-col sm:hidden ">
            <Link to={"/?lang=" + language} className="flex items-baseline">
              <h1 className="font-viga text-3xl text-textGray lg:text-2xl">
                STIL DARLEAN GRUP
              </h1>
            </Link>
            <nav className="flex gap-x-10 lg:ml-4 text-xl lg:text-base md:text-base md:gap-x-6">
              <Link
                to={"/?lang=" + language}
                className="text-textGray relative"
              >
                {language == "ro" ? "Acasă" : "Главная"}
                <motion.div
                  initial={{ width: 0 }}
                  animate={{ width: currentPage == "/" ? "100%" : 0 }}
                  className="bg-primary h-0.5"
                ></motion.div>
              </Link>
              <Link
                to={"/categories?lang=" + language}
                className="text-textGray relative"
              >
                {language == "ro" ? "Produse și servicii" : "Продукты и услуги"}
                <motion.div
                  initial={{ width: 0 }}
                  animate={{
                    width:
                      currentPage.includes("categorie") ||
                      currentPage.includes("products")
                        ? "100%"
                        : 0,
                  }}
                  className="bg-primary h-0.5"
                ></motion.div>
              </Link>
              <Link
                to={"/contact?lang=" + language}
                className="text-textGray relative"
              >
                {language == "ro" ? "Contacte" : "Контакты"}
                <motion.div
                  initial={{ width: 0 }}
                  animate={{
                    width: currentPage.includes("contact") ? "100%" : 0,
                  }}
                  className="bg-primary h-0.5"
                ></motion.div>
              </Link>
              <div className="h-7 w-[0.1rem] md:hidden scale-125 bg-primary"></div>
            </nav>
          </div>
          <div className="w-fit sm:w-full flex sm:flex-wrap sm:justify-between sm:gap-x-4 sm:gap-y-2">
            <span
              onClick={() => setExpandMenu(true)}
              className="hidden w-fit sm:block sm:order-1 cursor-pointer"
            >
              <MenuIcon className="text-white" />
            </span>
            <div className="w-fit flex gap-x-8 lg:gap-x-4 items-center sm:mx-auto sm:order-3">
              <a
                href="tel:+37369097890"
                className="text-white flex gap-x-2 sm:text-sm"
              >
                <PhoneInTalkIcon />
                <span>+373 69 097 890</span>
              </a>
              <div className="h-7 sm:h-5 w-[0.1rem] scale-125 bg-primary"></div>
              <div className="flex gap-x-3 text-white">
                <a href="tel:+37369097890">
                  <TelegramIcon className="sm:w-[0.85rem]" />
                </a>
                <a href="tel:+37369097890">
                  <WhatsAppIcon className="sm:w-[0.85rem]" />
                </a>
                <a href="">
                  <InstagramIcon className="sm:w-[0.85rem]" />
                </a>
                <a href="">
                  <FacebookIcon className="sm:w-[0.85rem]" />
                </a>
              </div>
              <div className="flex text-white text-xl gap-x-2 sm:hidden">
                <span
                  onClick={() => setLanguage("ro")}
                  className={
                    "cursor-pointer " +
                    (language == "ro" ? "font-semibold" : "")
                  }
                >
                  RO
                </span>{" "}
                <div className="h-7 w-[0.1rem] scale-125 bg-primary"></div>
                <span
                  onClick={() => setLanguage("ru")}
                  className={
                    "cursor-pointer " +
                    (language == "ru" ? "font-semibold" : "")
                  }
                >
                  RU
                </span>
              </div>
            </div>
            <div className=" text-white text-xl gap-x-2 hidden sm:flex sm:order-2">
              <span
                onClick={() => setLanguage("ro")}
                className={
                  "cursor-pointer " + (language == "ro" ? "font-semibold" : "")
                }
              >
                RO
              </span>{" "}
              <div className="h-5 w-[0.1rem] scale-125  bg-primary"></div>
              <span
                onClick={() => setLanguage("ru")}
                className={
                  "cursor-pointer " + (language == "ru" ? "font-semibold" : "")
                }
              >
                RU
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-y-auto">
        <Outlet />
      </div>
      <div className="my-20"></div>
      <div className="mt-auto flex justify-between bg-backgroundDark py-10 px-8 gap-x-8 sm:flex-col sm:gap-y-8">
        <div className="w-2/3 sm:w-full md:w-3/4 flex flex-col">
          <h1 className="flex text-white items-baseline font-viga text-5xl gap-x-2 font-medium">
            STIL DARLEAN GRUP
          </h1>
          <p className="text-white mt-2">
            {language == "ro"
              ? "Construcții de încredere cu personal profesionist și echipament profesional. Cu ani de experiență în spate, garantăm furnizarea și instalarea celor procurate."
              : "Надежные конструкции с профессиональным персоналом и профессиональным оборудованием. С годами опыта в качестве гарантии поставки и установки приобретенных."}
          </p>
          <div className="flex gap-x-8 mt-4 text-white">
            <a href="https://t.me/gravaj">
              <TelegramIcon className="scale-125" />
            </a>
            <a href="https://wa.me/gravaj">
              <WhatsAppIcon className="scale-125" />
            </a>
            <a href="https://instagram.com/gravaj">
              <InstagramIcon className="scale-125" />
            </a>
            <a href="https://facebook.com/gravaj">
              <FacebookIcon className="scale-125" />
            </a>
          </div>
          <ul className="text-textGray text-sm mt-auto sm:mt-8">
            <li>{language == "ro" ? "Mun. Chișinău" : "г. Кишинев"}</li>
            <li>
              {language == "ro"
                ? "Telefon: +373 690 97 890"
                : "Телефон: +373 690 97 890"}
            </li>
            <li>
              {language == "ro"
                ? "Email: stildarlean@gmail.com"
                : "Эл. адрес: stildarlean@gmail.com"}
            </li>
          </ul>
        </div>
        <div className="w-1/3 md:w-1/4 sm:w-full sm:justify-start sm:h-fit justify-end gap-x-20 mx-auto h-64 rounded-2xl flex text-white md:flex-col gap-8 md:justify-start">
          <div className="flex flex-col">
            <h3 className="font-medium text-xl">
              {language == "ro" ? "Categorii" : "Категории"}
            </h3>
            <ul className=" font-normal flex-col flex gap-y-2 mt-2">
              {categories.map((category) => (
                <li>
                  <Link
                    to={"/categorie/" + category.slug + "?lang=" + language}
                  >
                    {language == "ro"
                      ? category.abbreviation
                      : category.ru_abbreviation}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="font-medium text-xl">
              {language == "ro" ? "Servicii" : "Услуги"}
            </h3>
            <ul className=" font-normal flex-col flex gap-y-2 mt-2">
              <Link to={"/contact?lang=" + language}>
                {language == "ro" ? "Contact" : "Контакт"}
              </Link>
            </ul>
          </div>
          {/* {!isLoaded ? (
            <h1>Loading...</h1>
          ) : (
            <GoogleMap
              onLoad={(map) => {
                const bounds = new window.google.maps.LatLngBounds();
                map.fitBounds(bounds);
              }}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              center={center}
              zoom={16}
            >
              <MarkerF position={center} label="My bussiness" />
            </GoogleMap>
          )} */}
        </div>
      </div>
      <div className="bg-backgroundDark text-white text-sm text-right px-8 py-2 ">
        made by <a href="scriptify.ro">scriptify</a>
      </div>
    </div>
  );
};

export default UserLayout;
