import React, { useState, useEffect } from "react";
import axios from "axios";
import api_url from "../constants";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProductCard from "./ProductCard";
import useMediaQuery from "../hooks/useMediaQuery";
const ProductsCarousel = ({ products, id, type = "small" }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productPages, setProductPages] = useState([]);
  const isLG = useMediaQuery("(min-width: 1024px)");
  const isXL = useMediaQuery("(min-width: 1280px)");
  const isMD = useMediaQuery("(min-width: 641px)");
  const isSM = useMediaQuery("(max-width: 640px)");
  useEffect(() => {
    const images = document.querySelectorAll("img");
    images.forEach((image) => {
      image.addEventListener("dragstart", (e) => e.preventDefault());
      image.setAttribute("draggable", false);
    });

    const pages = [];
    if (type == "small") {
      if (isSM) {
        for (let i = 0; i < Math.ceil(products.length / 4); i++) {
          pages.push(i);
        }
      }
      if ((isLG && !isXL) || (isMD && !isLG)) {
        for (let i = 0; i < Math.ceil(products.length / 6); i++) {
          pages.push(i);
        }
      }
      if (isXL || (isLG && !isMD)) {
        for (let i = 0; i < Math.ceil(products.length / 8); i++) {
          pages.push(i);
        }
      }
    } else {
      if (isSM) {
        for (let i = 0; i < Math.ceil(products.length / 4); i++) {
          pages.push(i);
        }
      }
      if ((isLG && !isXL) || (isMD && !isLG)) {
        for (let i = 0; i < Math.ceil(products.length / 8); i++) {
          pages.push(i);
        }
      }
      if (isXL || (isLG && !isMD)) {
        for (let i = 0; i < Math.ceil(products.length / 10); i++) {
          pages.push(i);
        }
      }
    }
    setProductPages(pages);
  }, [products]);

  useEffect(() => {
    const carousel = document.getElementById(id);
    carousel.scrollLeft = (currentPage - 1) * carousel.clientWidth;
  }, [currentPage]);

  useEffect(() => {
    const change_page = setInterval(() => {
      setCurrentPage(currentPage == productPages.length ? 1 : currentPage + 1);
    }, 4000);
    return () => clearInterval(change_page);
  }, []);
  return (
    <div className="relative w-full mx-auto">
      <div className="overflow-x-auto scrollbar-none" id={id}>
        <button
          onClick={() => {
            setCurrentPage(
              currentPage == 1 ? productPages.length : currentPage - 1
            );
          }}
          className="transition-colors ease-in-out duration-300 hover:bg-black/30 p-3 sm:p-2 absolute top-1/2 left-0 trnslate-y-[-50%] translate-x-[-100%] sm:translate-x-[-50%] w-fit rounded-full bg-black/20"
        >
          <ArrowForwardIosIcon className="w-6 sm:w-2 opacity-40 rotate-180 " />
        </button>
        <button
          onClick={() => {
            setCurrentPage(
              currentPage == productPages.length ? 1 : currentPage + 1
            );
          }}
          className="transition-colors ease-in-out duration-300 sm:p-2 hover:bg-black/30 p-3 absolute top-1/2 right-0 trnslate-y-[-50%] translate-x-[100%] sm:translate-x-[50%] w-fit rounded-full bg-black/20"
        >
          <ArrowForwardIosIcon className="w-6 sm:w-3 opacity-40" />
        </button>
        <div
          className={"flex flex-nowrap overflow-x-auto  "}
          style={{
            width: productPages.length * 100 + "%",
          }}
        >
          {productPages.map((productPage) => (
            <div
              className={
                "grid  gap-x-20  px-10 overflow-y-hidden gap-y-8 w-full " +
                (type == "big"
                  ? "grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1"
                  : "lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 grid-cols-3")
              }
            >
              {products
                .slice(
                  type == "small"
                    ? isSM
                      ? productPage * 2
                      : (isLG && !isXL) || (isMD && !isLG)
                      ? productPage * 4
                      : productPage * 6
                    : isSM
                    ? productPage * 2
                    : (isLG && !isXL) || (isMD && !isLG)
                    ? productPage * 6
                    : productPage * 8,
                  type == "small"
                    ? isSM
                      ? productPage * 2 + 2
                      : (isLG && !isXL) || (isMD && !isLG)
                      ? productPage * 4 + 4
                      : productPage * 6 + 6
                    : isSM
                    ? productPage * 2 + 2
                    : (isLG && !isXL) || (isMD && !isLG)
                    ? productPage * 6 + 6
                    : productPage * 8 + 8
                )
                .map((product) => (
                  <ProductCard type={type} product={product} />
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductsCarousel;
