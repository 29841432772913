import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import api_url, { media_url } from "../constants";
import ImageCropModal from "../components/ImageCropModal";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";

const AdminEditCategory = () => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);
  const { id } = useParams();
  const [category, setCategory] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${api_url}/category/${id}/`).then((res) => {
      setCategory(res.data);
    });
  }, []);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFileType(file.type);
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  function ModifyCategory(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (resultFile) {
      formData.append("image", resultFile);
      formData.append("image_type", fileType.split("/")[1]);
    }
    axios
      .put(`${api_url}/category/${id}/`, formData)
      .then((res) => {
        navigate("/admin/categories");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <form
      onSubmit={ModifyCategory}
      className="flex w-full gap-x-4 flex-wrap sm:justify-center gap-y-6"
    >
      {resultImage ? (
        <label
          htmlFor="product-graphics"
          className="w-56 block relative text-white h-72 bg-backgroundGray group cursor-pointer"
        >
          <img src={resultImage} className="w-full" />
          <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
          <FlipCameraAndroidIcon className="w-10 text-white absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150" />
          <input
            ref={file}
            onChange={selectFile}
            id="product-graphics"
            type="file"
            className="hidden"
          />
        </label>
      ) : (
        <label
          htmlFor="product-graphics"
          className="w-56 block relative text-white h-72 bg-backgroundGray group cursor-pointer"
        >
          <img src={media_url + category.image} className="w-full" />
          <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
          <FlipCameraAndroidIcon className="w-10 text-white absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150" />
          <input
            ref={file}
            onChange={selectFile}
            id="product-graphics"
            type="file"
            className="hidden"
          />
        </label>
      )}
      <div className="w-72 flex flex-col gap-y-4">
        <input
          defaultValue={category.name}
          name="name"
          className="admin-input w-full"
          placeholder="Nume categorie"
        />
        <textarea
          defaultValue={category.description}
          className="admin-input w-full min-h-[14.6rem]"
          placeholder="Descriere categorie"
          name="description"
        ></textarea>
      </div>
      <div className="flex flex-col gap-y-4">
        <Link to="/admin/categories" className="admin-alert-button px-8">
          Anulează modificările
        </Link>
        <button className="admin-primary-button px-8">
          Salează modificări
        </button>
      </div>
      <ImageCropModal
        aspectRatio={7 / 9}
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
      />
    </form>
  );
};

export default AdminEditCategory;
