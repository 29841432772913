import React, { useState, useEffect } from "react";
import axios from "axios";
import api_url, { media_url } from "../constants";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
const AdminPromotions = () => {
  const [products, setProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [page, setPage] = useState(1);
  const [productsCount, setProductsCount] = useState([]);

  useEffect(() => {
    axios
      .get(api_url + "/products-count/")
      .then((res) => {
        const count = res.data;
        const product_pages = [];
        for (let i = 0; i < Math.ceil(count / 25); i++) {
          product_pages.push(i);
          setProductsCount(product_pages);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    axios.get(api_url + "/promotions/").then((res) => {
      setPromotions(res.data);
    });
  }, []);

  useEffect(() => {
    axios
      .get(api_url + "/products/?limit=25&page=" + page)
      .then((res) => {
        const products = res.data.filter((product) => !product.at_promotion);
        setProducts(products);
      })
      .catch((err) => console.log(err));
  }, [page]);

  function SetPromotion(id) {
    axios
      .put(api_url + "/product/" + id + "/", { at_promotion: true })
      .then((res) => {
        setPromotions([...promotions, res.data]);
        setProducts(products.filter((product) => product.id !== id));
      })
      .catch((err) => console.log(err));
  }

  function RemovePromotion(id) {
    axios
      .put(api_url + "/product/" + id + "/", { at_promotion: false })
      .then((res) => {
        setProducts([...products, res.data]);
        setPromotions(promotions.filter((product) => product.id !== id));
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="grid grid-cols-2 sm:grid-cols-1 gap-y-10 sm:mt-4 gap-x-10">
      <div>
        <h2 className="font-medium text-lg">Produse la promoție</h2>
        <div className="flex flex-col gap-y-3">
          {promotions.map((product) => (
            <div
              onClick={() => RemovePromotion(product.id)}
              className="grid shadow-md items-center gap-x-4 p-2 cursor-pointer grid-cols-[3rem,1fr,2rem] bg-white"
            >
              <img src={media_url + product.image_1} className="w-full" />
              <div>{product.name}</div>
              <RemoveIcon />
            </div>
          ))}
        </div>
      </div>
      <div>
        <h2 className="font-medium text-lg">Lista tuturor produselor</h2>
        <div className="flex gap-x-4 mt-4">
          {page > 4 && (
            <div
              onClick={() => setPage(1)}
              className={
                "w-8 h-8 flex cursor-pointer items-center justify-center rounded-full " +
                (page == 1
                  ? "bg-primary text-white"
                  : "bg-gray-200 text-gray-500")
              }
            >
              1
            </div>
          )}
          {page > 4 && (
            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 text-gray-500">
              ...
            </div>
          )}

          {(productsCount.length > 5 && (
            <div className="flex gap-x-4">
              {productsCount
                .slice(page > 4 ? page - 3 : 0, page > 4 ? Number(page) + 2 : 5)
                .map((product_count) => (
                  <div
                    onClick={() => setPage(product_count + 1)}
                    className={
                      "w-8 h-8 flex cursor-pointer items-center justify-center rounded-full " +
                      (page == product_count + 1
                        ? "bg-primary text-white"
                        : "bg-gray-200 text-gray-500")
                    }
                  >
                    {product_count + 1}
                  </div>
                ))}
              {page < productsCount.length - 2 ? "..." : ""}
              {page < productsCount.length - 2 && (
                <div
                  onClick={() => setPage(productsCount.length)}
                  className={
                    "w-8 h-8 flex cursor-pointer items-center justify-center rounded-full " +
                    (page == productsCount.length
                      ? "bg-primary text-white"
                      : "bg-gray-200 text-gray-500")
                  }
                >
                  {productsCount.length}
                </div>
              )}
            </div>
          )) ||
            productsCount.map((product_count) => (
              <div
                onClick={() => setPage(product_count + 1)}
                className={
                  "w-8 h-8 flex cursor-pointer items-center justify-center rounded-full " +
                  (page == product_count + 1
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-500")
                }
              >
                {product_count + 1}
              </div>
            ))}
        </div>
        <div className="flex flex-col gap-y-3 mt-4">
          {products.map((product) => (
            <div
              onClick={() => SetPromotion(product.id)}
              className="grid shadow-md items-center gap-x-4 p-2 cursor-pointer grid-cols-[3rem,1fr,2rem] bg-white"
            >
              <img src={media_url + product.image_1} className="w-full" />
              <div>{product.name}</div>
              <AddIcon />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPromotions;
