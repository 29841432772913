import React, { useMemo, useEffect, useState } from "react";
import wallpaper from "../static/images/wallpaper.jpg";
// import gravaj from "../static/images/gravaj.svg";
import { motion, useScroll } from "framer-motion";
// import { Link } from "react-router-dom";
// import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
// import TelegramIcon from "@mui/icons-material/Telegram";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import InstagramIcon from "@mui/icons-material/Instagram";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
// import FacebookIcon from "@mui/icons-material/Facebook";
import diagram_1 from "../static/images/diagram-1.svg";
import section_attachment from "../static/images/section-attachment.svg";
import presentation_1 from "../static/images/presentation-1.svg";
import decoration_1 from "../static/images/decoration-1.svg";
import decoration_2 from "../static/images/decoration-2.svg";
import decoration_3 from "../static/images/decoration-3.svg";
import decoration_4 from "../static/images/decoration-4.svg";
import decoration_5 from "../static/images/decoration-5.svg";
import decoration_6 from "../static/images/decoration-6.svg";
import presentation_2 from "../static/images/presentation-2.png";
import axios from "axios";
import api_url from "../constants";
import ProductsCarousel from "../components/ProductsCarousel";
import FadeIn from "../components/FadeIn";
import check from "../static/images/check.svg";
import LoadingSpinner from "../components/LoadingSpinner";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../store";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const { language, setLanguage } = useContext(LanguageContext);

  let [args] = useSearchParams();
  let lang = args.get("lang");
  if (lang == null) {
    lang = "ro";
  }

  useEffect(() => {
    setLanguage(lang);
  }, []);

  const features = [
    language == "ro" ? "Calitate" : "Качество",
    language == "ro" ? "Eficiență" : "Эффективность",
    language == "ro" ? "Inovație" : "Инновация",
    language == "ro" ? "Fiabilitate" : "Надежность",
    language == "ro" ? "Durabilitate" : "Долговечность",
    language == "ro" ? "Experiență" : "Опыт",
  ];

  const competences = [
    {
      name:
        language == "ro"
          ? "Inovație și Calitate Garantată"
          : "Инновация и гарантированное качество",
      description:
        language == "ro"
          ? "În ceea ce privește serviciile oferite, suntem mereu la curent cu cele mai recente tehnologii și tendințe din industrie. Garantăm clienților noștri nu doar materiale de cea mai înaltă calitate, ci și soluții inovatoare care aduc un plus de valoare și estetică proiectelor noastre."
          : "Что касается предлагаемых услуг, мы всегда в курсе последних технологий и тенденций в отрасли. Мы гарантируем нашим клиентам не только материалы самого высокого качества, но и инновационные решения, которые придают дополнительную ценность и эстетику нашим проектам.",
    },
    {
      name:
        language == "ro"
          ? "Soluții Personalizate pentru Fiecare Proiect"
          : "Персонализированные решения для каждого проекта",
      description:
        language == "ro"
          ? "Punem la dispoziție soluții personalizate care să se potrivească perfect cerințelor și gusturilor fiecărui client. Fie că este vorba despre amenajarea unei grădini, a unei curți sau a unui spațiu comercial, colaborăm îndeaproape cu clienții pentru a crea un plan personalizat care să îmbine funcționalitatea cu estetica."
          : "Мы предлагаем индивидуальные решения, которые идеально подходят для требований и вкусов каждого клиента. Независимо от того, идет ли речь об оформлении сада, двора или коммерческого помещения, мы тесно сотрудничаем с клиентами, чтобы создать индивидуальный план, который сочетает в себе функциональность и эстетику.",
    },
    {
      name:
        language == "ro"
          ? "Rezultate Concrete și în Timp Rapid"
          : "Конкретные результаты и быстрое время",
      description:
        language == "ro"
          ? "Ne propunem să oferim rezultate concrete și durabile, respectând totodată termenele stabilite. Fie că este vorba despre punerea în aplicare a unor pavaje elegante, a unei gresii moderne sau a unui gard solid, echipa noastră de profesioniști lucrează cu seriozitate și responsabilitate pentru a livra proiecte finalizate în timp util. Astfel, clienții noștri se pot bucura rapid de transformările dorite în spațiile lor."
          : "Мы стремимся предоставлять конкретные и долговременные результаты, соблюдая при этом установленные сроки. Независимо от того, идет ли речь о реализации элегантных тротуаров, современной плитки или прочного забора, наша команда профессионалов работает серьезно и ответственно, чтобы своевременно завершить проекты. Таким образом, наши клиенты могут быстро насладиться желаемыми преобразованиями в своих пространствах.",
    },
  ];

  useEffect(() => {
    axios.get(api_url + "/categories/?lang=" + language).then((res) => {
      const res_categories = res.data;
      setCategories(res_categories);
      setSelectedCategory(res_categories[0]);
      axios
        .get(api_url + "/products/" + res_categories[0].id + "/?limit=25")
        .then((res) => {
          setProducts(res.data);
        });
    });

    axios.get(api_url + "/promotions/").then((res) => {
      setPromotions(res.data);
    });
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      axios
        .get(api_url + "/products/" + selectedCategory.id + "/?limit=25")
        .then((res) => {
          setProducts(res.data);
        });
    }
  }, [selectedCategory]);
  const [messageSent, setMessageSent] = useState(false);
  function SendMessage(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    setIsMessageSending(true);
    axios.post(api_url + "/message/", formData).then((res) => {
      setMessageSent(true);
      setIsMessageSending(false);
    });
  }

  return (
    <div className=" overflow-auto scrollbar-none">
      <Helmet>
        <title>Stil Darlean</title>
        <link rel="canonical" href="stildarlean.md" />
        <meta
          name="description"
          content={
            language == "ro"
              ? "Stil Darlean Group este o companie care oferă servicii de furnizare și instalare a materialelor de construcție, de la pavaje - la gresie și garduri."
              : "Stil Darlean Group - компания, предоставляющая услуги по поставке и установке строительных материалов, от тротуаров до плитки и заборов."
          }
        />
      </Helmet>
      <div className="relative w-full min-h-[100vh] sm:min-h-[120vh] min-w-[100vw] md:min-h-[120vh]">
        <motion.img
          initial={{ scale: 1, opacity: 0.85 }}
          animate={{ scale: 1.1, opacity: 1 }}
          transition={{ duration: 3, type: "spring" }}
          src={wallpaper}
          className="min-h-[100vh] min-w-[100vw] md:min-h-[120vh] sm:min-h-[120vh] h-full object-cover"
        />
        <div className="top-0 left-0 right-0 bottom-0 h-full bg-black/30 absolute"></div>
        <div className="mt-32 md:mt-20 sm:mt-20 top-0 absolute right-0 left-0 mx-56 lg:mx-16 xl:mx-36 md:mx-16 sm:mx-6 flex justify-between md:flex-col sm:flex-col">
          <div className="relative w-1/2 md:w-fit mx-auto sm:w-fit">
            <img
              src={diagram_1}
              className="w-72 drop-shadow-lg md:rotate-90 sm:rotate-90 sm:w-full"
            />
            <div className="absolute flex flex-col gap-y-8 top-1/2 md:w-full translate-x-16 md:-translate-y-1/3 md:-translate-x-0 sm:-translate-y-1/3 sm:-translate-x-0 -translate-y-1/2">
              <h1 className="stroke-black text-white font-medium text-stroke text-5xl w-full md:text-3xl md:text-center sm:text-2xl sm:text-center">
                {language == "ro" ? "Furnizare și" : "Поставка и"}
                <br className="md:hidden" />
                {language == "ro"
                  ? " Instalare Durabilă"
                  : " Устойчивая Установка"}
              </h1>
              <p className="bg-black/40 backdrop-blur-sm w-fit sm:px-4 sm:py-4 sm:text-lg text-center px-16 text-primary font-medium text-stroke shadow-md py-4 text-3xl">
                {language == "ro" ? "+530 clienți" : "+530 клиентов"}
                <br className="sm:hidden" />
                {language == "ro" ? " mulțumiți" : " довольных"}
              </p>
            </div>
          </div>
          {((messageSent || isMessageSending) && (
            <div className="flex flex-col relative gap-y-4 bg-black/60 px-12 py-6 md:w-3/4 justify-center items-center md:mx-auto md:-translate-y-6 ">
              {isMessageSending && <LoadingSpinner color="#E8B70D" />}
              <img
                src={check}
                className={
                  " w-16 mx-auto transition-opacity ease-in-out duration-300" +
                  (isMessageSending && " opacity-0")
                }
              />
              <div
                className={
                  "text-primary font-medium transition-opacity ease-in-out duration-300 text-center " +
                  (isMessageSending && "opacity-0")
                }
              >
                <p>
                  {language == "ro"
                    ? "Mesajul a fost trimis și recepționat cu succes!"
                    : "Сообщение было успешно отправлено и получено!"}
                </p>{" "}
                <p>
                  {language == "ro"
                    ? "Vom reveni cu un apel."
                    : "Мы перезвоним."}
                </p>
              </div>
            </div>
          )) || (
            <form
              onSubmit={SendMessage}
              className="bg-black/60 lg:min-w-[25rem] xl:min-w-[25rem] 2xl:min-w-[25rem] px-12 py-6 md:w-3/4 md:mx-auto flex-col flex md:-translate-y-6"
            >
              <p className="text-primary text-2xl font-semibold">
                {language == "ro"
                  ? "Completează formularul și"
                  : "Заполните форму и"}
                <br />
                {language == "ro" ? " te sunăm noi" : " мы вам перезвоним"}
              </p>
              <div className="mt-8 flex flex-col gap-y-8">
                <input
                  className="contact-input"
                  placeholder={language == "ro" ? "Nume" : "Имя"}
                  name="name"
                />
                <input
                  className="contact-input"
                  placeholder={language == "ro" ? "Telefon" : "Телефон"}
                  name="phone"
                />
                <textarea
                  name="message"
                  minLength={10}
                  className="contact-input"
                  placeholder={language == "ro" ? "Mesaj" : "Сообщение"}
                ></textarea>
              </div>
              <button className="text-primary px-8 py-4 text-lg font-medium mt-auto bg-buttonGray w-full">
                {language == "ro" ? "Trimite" : "Отправить"}
              </button>
            </form>
          )}
        </div>
      </div>

      <div className="mt-28 mx-56 lg:mx-16 xl:mx-36 md:mx-16 sm:mx-4 flex gap-x-12 lg:gap-x-6 md:flex-col sm:flex-col gap-y-6 sm:gap-y-10">
        <div className="w-3/4 md:w-full sm:w-full">
          <h2 className="text-6xl sm:text-4xl md:text-5xl font-semibold flex relative">
            <img
              src={section_attachment}
              className="w-10 absolute -translate-y-1/2 top-0 left-0"
            />
            <span className="ml-8">
              {language == "ro"
                ? "Noi lucrăm pentru tine"
                : "Мы работаем для вас"}
            </span>
          </h2>
          <div className="border-l-4 flex flex-col gap-y-4 mt-4 h-fit ml-10 border-l-primary pl-4">
            <p>
              {language == "ro"
                ? "Bine ați venit la Stil Darlean Grup, o companie dedicată transformării spațiilor exterioare în adevărate opere de artă funcționale. Cu o prezență activă în domeniu începând cu anul 2018, ne-am angajat să oferim soluții de înaltă calitate pentru amenajarea pavajelor, gresiei și gardurilor."
                : "Добро пожаловать в Stil Darlean Grup, компанию, посвященную превращению внешних пространств в настоящие функциональные произведения искусства. С активным присутствием в отрасли с 2018 года, мы обязали себя предоставлять высококачественные решения для оформления тротуаров, плитки и заборов."}
            </p>
            <p>
              {language == "ro"
                ? "La Stil Darlean Grup, ne mândrim cu faptul că am depășit așteptările a peste 530 de clienți satisfăcuți, oferindu-le soluții personalizate și rezultate remarcabile. Cu o abordare inovatoare și un angajament ferm față de calitate, am reușit să devenim un partener de încredere pentru cei care caută transformarea spațiilor exterioare în zone estetice, funcționale și durabile."
                : "В Stil Darlean Grup мы гордимся тем, что превзошли ожидания более 530 довольных клиентов, предоставив им индивидуальные решения и замечательные результаты. Благодаря инновационному подходу и твердому обязательству к качеству, мы смогли стать надежным партнером для тех, кто ищет преобразование внешних пространств в эстетические, функциональные и долговечные зоны."}
            </p>
          </div>
          <div className="grid grid-cols-3 w-full justify-between mt-8 pl-10 gap-x-4 gap-y-10 md:hidden">
            {features.map((feature, index) => (
              <FadeIn index={index}>
                {" "}
                <div className="flex gap-y-2 flex-col">
                  <span className="text-lg sm:text-sm font-semibold text-center">
                    {feature}
                  </span>
                  <TaskAltIcon className="mx-auto scale-110 sm:scale-100" />
                </div>
              </FadeIn>
            ))}
          </div>
        </div>
        <div className="w-1/2 sm:w-full md:w-full flex justify-between gap-x-8 md:mt-8 sm:mt-6">
          <div className="flex-wrap h-fit w-1/2 justify-between hidden md:flex pl-10 gap-x-8 gap-y-10">
            {features.slice(0, 5).map((feature, index) => (
              <FadeIn index={index}>
                {" "}
                <div className="flex gap-y-2 flex-col">
                  <span className="text-lg font-semibold text-center">
                    {feature}
                  </span>
                  <TaskAltIcon className="mx-auto scale-110" />
                </div>
              </FadeIn>
            ))}
          </div>
          <img src={presentation_1} className="w-full" />
        </div>
      </div>
      <div className="grid grid-cols-6 justify-between mt-10 mx-20 lg:mx-16 md:mx-8 sm:mx-0 sm:grid-cols-3 gap-y-6 gap-x-8">
        <FadeIn>
          <img src={decoration_1} className="mx-auto" />
        </FadeIn>
        <FadeIn>
          <img src={decoration_2} className="mx-auto" />
        </FadeIn>
        <FadeIn>
          <img src={decoration_3} className="mx-auto" />
        </FadeIn>
        <FadeIn>
          <img src={decoration_4} className="mx-auto" />
        </FadeIn>
        <FadeIn>
          <img src={decoration_5} className="mx-auto" />
        </FadeIn>
        <FadeIn>
          <img src={decoration_6} className="mx-auto" />
        </FadeIn>
      </div>
      <div className="mt-10 flex sm:flex-col">
        <img
          src={presentation_2}
          className="w-1/4 h-fit lg:w-[35%] md:hidden sm:w-full sm:px-8 sm:mx-auto"
        />
        <div className="px-10 py-20 my-16 bg-backgroundGray w-3/4 lg:w-[65%] lg:px-8 md:w-full sm:w-full">
          <h2 className="text-5xl font-semibold flex relative lg:text-4xl md:text-4xl sm:text-3xl">
            <img
              src={section_attachment}
              className="w-10 absolute -translate-y-1/2 top-0 left-0 sm:-left-5 sm:w-8"
            />
            <span className="ml-8 sm:ml-2">
              {language == "ro"
                ? "Profesionalism în Instalare Durabilă"
                : "Профессионализм в устойчивой установке"}
            </span>
          </h2>
          <ul className="flex flex-col gap-y-6 mt-8 pl-9 pr-40 lg:pr-10 md:pr-10 sm:pr-0 sm:pl-0">
            {competences.map((competence, index) => (
              <FadeIn>
                {" "}
                <li className="flex gap-x-4 items-start">
                  <TaskAltIcon className="scale-125 mt-1" />
                  <div>
                    <h3 className="font-medium text-xl sm:text-lg">
                      {competence.name}
                    </h3>
                    <p>{competence.description}</p>
                  </div>
                </li>
              </FadeIn>
            ))}
          </ul>
        </div>
      </div>
      <div className="mt-20 pb-20  mx-56 lg:mx-16 xl:mx-36 md:mx-16 sm:mx-4">
        <h2 className="text-5xl sm:text-4xl font-bold w-fit mx-auto relative">
          <span className="z-20 relative text-center mx-auto block">
            {language == "ro" ? "PRODUSELE OFERITE" : "ПРЕДЛАГАЕМЫЕ ПРОДУКТЫ"}
          </span>
          <div className="w-60 sm:w-40 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[-30%] drop-shadow-md rotate-[-9deg] h-1.5 bg-primary"></div>
        </h2>
        <div className="text-center text-lg flex flex-col sm:gap-y-2 mt-4 sm:text-base font-medium w-fit mx-auto">
          <p>
            {language == "ro"
              ? "Ne ocupăm atât cu furnizarea tuturor materialelor, cât și cu instalarea acestora."
              : "Мы занимаемся как поставкой всех материалов, так и их установкой."}
          </p>
          <p>
            {" "}
            {language == "ro"
              ? "Mai jos găsiți categoriile de produse pe care oferim."
              : "Ниже вы найдете категории продуктов, которые мы предлагаем."}
          </p>
        </div>
        <div className="grid mt-10 grid-cols-[14rem,1fr] gap-x-20 lg:gap-x-16 md:grid-cols-1 md:gap-y-12 sm:grid-cols-1 sm:gap-y-10">
          <div className="bg-backgroundGray shadow-md rounded-2xl flex flex-col gap-y-20 py-20 px-4 md:flex-row md:py-8 sm:gap-y-12 sm:py-12">
            {categories.map((category) => (
              <div
                onClick={() => setSelectedCategory(category)}
                className="text-2xl cursor-pointer w-fit mx-auto text-center
                font-medium"
              >
                <span className="px-2">
                  {language == "ro"
                    ? category.abbreviation
                    : category.ru_abbreviation}
                </span>
                <motion.div
                  initial={{ width: 0 }}
                  animate={{
                    width: selectedCategory?.id == category.id ? "100%" : 0,
                  }}
                  className="bg-primary h-1"
                ></motion.div>
              </div>
            ))}
          </div>
          <ProductsCarousel
            productsPerRow={4}
            products={products}
            id="carousel-products"
          />
          {/* <div className="grid grid-cols-4 gap-x-16 gap-y-10">
            {products.map((product) => (
              <div className="flex flex-col">
                <img
                  src={api_url + product.image_1}
                  className="transition-allrounded-xl border-solid border-black border-2"
                />
                <div className="text-sm font-medium text-center mt-2">
                  {product.name}
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <div className="mt-12 mx-56 lg:mx-16 xl:mx-36 md:mx-16 sm:mx-4">
        <h2 className="text-5xl sm:text-4xl mb-8 font-bold w-fit mx-auto relative">
          <span className="z-20 relative mx-auto text-center block">
            {language == "ro" ? "PROMOȚIILE VALABILE" : "ДЕЙСТВУЮЩИЕ АКЦИИ"}
          </span>
          <div className="w-60 sm:w-40 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[-30%] drop-shadow-md rotate-[-9deg] h-1.5 bg-primary"></div>
        </h2>
        <ProductsCarousel
          type="big"
          products={promotions}
          id="carousel-promotions"
        />
      </div>
    </div>
  );
};

export default Home;
