import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import api_url from "../constants";
import axios from "axios";
import InventoryIcon from "@mui/icons-material/Inventory";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import ClassIcon from "@mui/icons-material/Class";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
const AdminLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pageName = location.pathname.split("/")[2];
  const [toggleNavBar, setToggleNavBar] = useState(false);
  const [code, setCode] = useState("");
  const [accessGranted, setAccessGranted] = useState(false);
  const isMD = useMediaQuery("(min-width: 768px)");
  const isLG = useMediaQuery("(min-width: 1024px)");
  const isSM = useMediaQuery("(max-width: 640px)");

  useEffect(() => {
    if (location.pathname === "/admin") {
      navigate("/admin/products");
    }
    const code = localStorage.getItem("code");
    axios
      .post(api_url + "/verify-code/", { code })
      .then((res) => {
        if (res.status === 200) {
          setAccessGranted(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function getAccess(e) {
    e.preventDefault();
    const code = e.target.code.value;
    axios
      .post(api_url + "/verify-code/", { code })
      .then((res) => {
        if (res.status === 200) {
          setAccessGranted(true);
          localStorage.setItem("code", code);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return !accessGranted ? (
    <form
      onSubmit={getAccess}
      className=" absolute top-1/2 left-1/2 gap-y-4 translate-x-[-50%] translate-y-[-50%] w-80 sm:w-full px-4 flex flex-col"
    >
      <input
        name="code"
        className="admin-input"
        placeholder="Parola de Admin"
        onChange={(e) => setCode(e.target.value)}
        value={code}
      />
      <button className="admin-primary-button">Accesează Admin Panel</button>
    </form>
  ) : (
    <div className="flex">
      <motion.div
        className={
          "bg-backgroundGray relative overflow-x-auto sm:absolute md:absolute ease-in-out duration-300 transition-all left-0 right-0 bottom-0 top-0 z-50 scrollbar-none " +
          (toggleNavBar ? "w-80 md:w-full sm:w-full" : "w-0")
        }
      >
        <div
          className={
            "bg-backgroundGray sm:py-4 py-8 min-h-[100vh] w-full min-w-[15rem] sm:absolute"
          }
        >
          <div
            className={
              "w-fit ml-auto mr-6 hidden " +
              (toggleNavBar ? " sm:block md:block" : " hidden")
            }
          >
            <MenuIcon
              onClick={() => setToggleNavBar(!toggleNavBar)}
              className={"w-8 hidden cursor-pointer"}
            />
          </div>
          <h1 className="font-montecarlo sm:mt-2 text-2xl text-center font-semibold mx-auto text-black drop-shadow-md w-fit">
            STIL DARLEAN <br /> GRUP ADMIN
          </h1>
          <ul className="text-black flex flex-col mt-6 text-lg">
            <Link
              to={"/admin/products"}
              className={
                "w-fit py-2 px-4  flex items-center gap-x-3" +
                (pageName === "services" ? " font-semibold" : " font-medium")
              }
              onClick={() =>
                (isSM || (isMD && !isLG)) && setToggleNavBar(false)
              }
            >
              <InventoryIcon className="w-5" />
              Produse
            </Link>
            <Link
              to={"/admin/categories"}
              className={
                "w-fit py-2 px-4  flex items-center gap-x-3" +
                (pageName === "services" ? " font-semibold" : " font-medium")
              }
              onClick={() =>
                (isSM || (isMD && !isLG)) && setToggleNavBar(false)
              }
            >
              <ClassIcon className="w-5" />
              Categorii
            </Link>
            <Link
              to={"/admin/promotions"}
              className={
                "w-fit py-2 px-4  flex items-center gap-x-3" +
                (pageName === "services" ? " font-semibold" : " font-medium")
              }
              onClick={() =>
                (isSM || (isMD && !isLG)) && setToggleNavBar(false)
              }
            >
              <LoyaltyIcon className="w-5" />
              Promoții
            </Link>
            {/* <Link
            to={"/admin/program"}
            className={
              "w-fit py-2 px-4 font-medium flex items-center gap-x-3 " +
              (pageName === "categories" ? "font-semibold" : "font-medium")
            }
            onClick={() => setToggleNavBar(false)}
          >
            <img src={clock_light} className="w-5" />
            Program de lucru
          </Link>
          <Link
            to={"/admin/appointments"}
            className={
              "w-fit py-2 px-4  flex items-center gap-x-3" +
              (pageName === "services" ? " font-semibold" : " font-medium")
            }
            onClick={() => setToggleNavBar(false)}
          >
            <img src={calendar} className="w-5" />
            Programări
          </Link> */}
          </ul>
        </div>
      </motion.div>
      <motion.div
        className={
          " sm:w-full md:w-full ease-in-out duration-300 transition-all" +
          ((!toggleNavBar && " w-full") || " w-[calc(100vw-16rem)]")
        }
      >
        <div className="w-full h-10 md:h-12 bg-backgroundGray flex items-center px-6 text-black uppercase font-semibold text-lg">
          <MenuIcon
            onClick={() => setToggleNavBar(!toggleNavBar)}
            className="w-8 cursor-pointer mr-6 my-2 hidden md:block sm:block"
          />
          <div className="flex justify-between w-full">
            {pageName}
            <LogoutIcon
              className="w-6 ml-auto cursor-pointer"
              onClick={() => {
                localStorage.removeItem("code");
                setAccessGranted(false);
              }}
            />
          </div>
        </div>
        <div className="p-8 sm:p-4 relative min-h-[calc(100vh-2.5rem)]">
          <Outlet />
        </div>
      </motion.div>
    </div>
  );
};

export default AdminLayout;
