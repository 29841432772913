import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api_url, { media_url } from "../constants";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import LoadingSpinner from "../components/LoadingSpinner";
const AdminProducts = () => {
  const [products, setProducts] = useState([]);
  const [productsCount, setProductsCount] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [searchedProducts, setSearchedProducts] = useState([]);

  useEffect(() => {
    axios
      .get(api_url + "/products-count/")
      .then((res) => {
        const count = res.data;
        const product_pages = [];
        for (let i = 0; i < Math.ceil(count / 25); i++) {
          product_pages.push(i);
          setProductsCount(product_pages);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios.get(api_url + "/products/?limit=25&page=" + page).then((res) => {
      setProducts(res.data);
      setLoading(false);
    });
  }, [page]);

  function searchProducts(e) {
    e.preventDefault();

    const query = e.target.query.value;

    axios
      .get(api_url + "/search-products/?query=" + query)
      .then((res) => {
        setProducts(res.data);
        const count = res.data.length;
        const product_pages = [];
        for (let i = 0; i < Math.ceil(count / 25); i++) {
          product_pages.push(i);
          setProductsCount(product_pages);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function AddSelectedProduct(product) {
    //check if is already in the array
    if (selectedProducts.includes(product)) {
      //remove it
      setSelectedProducts(selectedProducts.filter((item) => item != product));
    } else {
      //add it
      setSelectedProducts([...selectedProducts, product]);
    }
  }

  function DeleteProducts() {
    const ids = selectedProducts.map((product) => product.id);
    const response = window.confirm(
      "Sunteti sigur ca doriti sa stergeti produsele selectate?"
    );
    if (!response) return;
    axios
      .delete(api_url + "/products/", { data: { ids: ids } })
      .then((res) => {
        setLoading(true);
        axios.get(api_url + "/products/?limit=25&page=" + page).then((res) => {
          setProducts(res.data);
          setLoading(false);
        });
        setSelectedProducts([]);
      })

      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <div className="flex justify-between sm:flex-col gap-y-4">
        {" "}
        <Link
          to="/admin/products/add"
          className="py-1.5 admin-primary-button px-16 w-fit"
        >
          Adaugă produs
        </Link>
        <form onSubmit={searchProducts} className="flex sm:ml-auto">
          <input
            className="admin-input min-w-[20rem] sm:min-w-[15rem]"
            placeholder="Caută produs"
            name="query"
          />
          <button className="w-10 bg-buttonGray hover:opacity-80 ease-in-out duration-300 transition-opacity text-center cursor-pointer">
            <SearchIcon
              style={{
                height: "100%",
                color: "#E8B70D",
                backgroundColor: "#474747",
              }}
            />
          </button>
        </form>
      </div>
      <div className="flex justify-between items-center sm:flex-col">
        <div className="flex gap-x-4 mt-10 sm:mt-4">
          {page > 4 && (
            <div
              onClick={() => setPage(1)}
              className={
                "w-8 h-8 flex cursor-pointer items-center justify-center rounded-full " +
                (page == 1
                  ? "bg-primary text-white"
                  : "bg-gray-200 text-gray-500")
              }
            >
              1
            </div>
          )}
          {page > 4 && (
            <div className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 text-gray-500">
              ...
            </div>
          )}

          {(productsCount.length > 5 && (
            <div className="flex gap-x-4">
              {productsCount
                .slice(page > 4 ? page - 3 : 0, page > 4 ? Number(page) + 2 : 5)
                .map((product_count) => (
                  <div
                    onClick={() => setPage(product_count + 1)}
                    className={
                      "w-8 h-8 flex cursor-pointer items-center justify-center rounded-full " +
                      (page == product_count + 1
                        ? "bg-primary text-white"
                        : "bg-gray-200 text-gray-500")
                    }
                  >
                    {product_count + 1}
                  </div>
                ))}
              {page < productsCount.length - 2 ? "..." : ""}
              {page < productsCount.length - 2 && (
                <div
                  onClick={() => setPage(productsCount.length)}
                  className={
                    "w-8 h-8 flex cursor-pointer items-center justify-center rounded-full " +
                    (page == productsCount.length
                      ? "bg-primary text-white"
                      : "bg-gray-200 text-gray-500")
                  }
                >
                  {productsCount.length}
                </div>
              )}
            </div>
          )) ||
            productsCount.map((product_count) => (
              <div
                onClick={() => setPage(product_count + 1)}
                className={
                  "w-8 h-8 flex cursor-pointer items-center justify-center rounded-full " +
                  (page == product_count + 1
                    ? "bg-primary text-white"
                    : "bg-gray-200 text-gray-500")
                }
              >
                {product_count + 1}
              </div>
            ))}
        </div>
        <div className="flex gap-x-4 items-center sm:ml-auto sm:my-2">
          <button
            onClick={DeleteProducts}
            className="bg-red-500 mt-2 px-6 py-1.5 text-white"
          >
            Delete Products
          </button>
          {selectedProducts.length > 0 && (
            <div className="text-lg mt-1 font-medium">
              ({selectedProducts.length})
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <div className="pt-20 mx-auto w-fit">
          <LoadingSpinner absolute={false} />
        </div>
      ) : (
        <div className="grid grid-cols-5 gap-x-8 gap-y-6 mt-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
          {products.map((product) => (
            <div className="relative group hover:scale-[102%] hover:shadow-lg transition-all ease-in-out duration-300  cursor-pointer">
              <Link
                to={`/admin/products/${product.id}`}
                className="bg-white flex flex-col "
              >
                <img src={media_url + product.image_1} />
                <span className="block mt-2 p-2 font-medium sm:text-sm">
                  {product.name}
                </span>
              </Link>
              <div
                onClick={() => AddSelectedProduct(product)}
                className={
                  "w-10 h-10  ease-in-out duration-300 transition-opacity absolute top-0 right-0 bg-primary " +
                  (selectedProducts.filter((item) => item.id == product.id)
                    .length
                    ? "opacity-100"
                    : "opacity-0 group-hover:opacity-100")
                }
              >
                {selectedProducts.filter((item) => item.id == product.id)
                  .length > 0 ? (
                  <CheckIcon className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-8" />
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminProducts;
