import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import api_url from "../constants";
import { useNavigate, Link } from "react-router-dom";
import ImageCropModal from "../components/ImageCropModal";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";

const AdminAddCategory = () => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);
  const navigate = useNavigate();

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFileType(file.type);
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  function AddCategory(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("image", resultFile);
    formData.append("image_type", fileType.split("/")[1]);
    axios.post(`${api_url}/category/`, formData).then((res) => {
      navigate("/admin/categories");
    });
  }

  return (
    <form
      onSubmit={AddCategory}
      className="flex w-full gap-x-4 flex-wrap gap-y-6 sm:justify-center"
    >
      {resultImage ? (
        <label
          htmlFor="product-graphics"
          className="w-56 block relative text-white h-72 bg-backgroundGray group cursor-pointer"
        >
          <img src={resultImage} className="w-full" />
          <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
          <FlipCameraAndroidIcon className="w-10 text-white absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150" />
          <input
            ref={file}
            onChange={selectFile}
            id="product-graphics"
            type="file"
            className="hidden"
          />
        </label>
      ) : (
        <label className="w-56 block relative h-72 bg-backgroundGray group cursor-pointer">
          <span className="group-hover:scale-150 block ease-in-out duration-300 transition-all absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2  scale-125">
            <AddIcon />
          </span>
          <input
            ref={file}
            onChange={selectFile}
            type="file"
            className="hidden"
          />
        </label>
      )}
      <div className="w-72 flex flex-col gap-y-4">
        <input
          name="name"
          className="admin-input w-full"
          placeholder="Nume categorie"
        />
        <input
          name="abbreviation"
          className="admin-input w-full"
          placeholder="Abreviere"
        />
        <textarea
          className="admin-input w-full min-h-[11.4rem]"
          placeholder="Descriere categorie"
          name="description"
        ></textarea>
      </div>
      <div className="flex flex-col gap-y-4">
        <Link to="/admin/categories" className="admin-alert-button px-8">
          Anulează formular
        </Link>
        <button className="admin-primary-button px-8">Adaugă produs</button>
      </div>
      <ImageCropModal
        aspectRatio={7 / 9}
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
      />
    </form>
  );
};

export default AdminAddCategory;
