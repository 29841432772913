import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import api_url, { media_url } from "../constants";
import LoadingSpinner from "../components/LoadingSpinner";
const AdminCategories = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    axios.get(`${api_url}/categories/`).then((res) => {
      setCategories(res.data);
      setLoading(false);
    });
  }, []);

  return (
    <div className=" sm:mt-4">
      <Link
        to="/admin/categories/add"
        className="py-1.5 admin-primary-button px-16 block w-fit"
      >
        Adaugă categorie
      </Link>
      {loading ? (
        <div className="pt-20 mx-auto w-fit">
          <LoadingSpinner absolute={false} />
        </div>
      ) : (
        <div className="grid grid-cols-4 mt-10 gap-x-20 lg:gap-x-10 md:grid-cols-3 md:gap-x-10 sm:grid-cols-2 sm:gap-x-5 gap-y-8">
          {categories.map((category) => (
            <Link
              to={"/admin/categories/" + category.id + "/edit"}
              className="flex flex-col gap-y-1 group hover:scale-[101%] transition-all ease-in-out duration-300"
            >
              <div className="relative">
                <img
                  src={media_url + category.image}
                  className="rounded-lg w-full"
                />
                <div className="top-0 left-0 right-0 bottom-0 absolute group-hover:bg-black/20 transition-colors ease-in-out duration-300"></div>
              </div>
              <h2 className="text-lg font-medium lg:text-base md:text-sm sm:text-sm">
                {category.name}
              </h2>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminCategories;
