import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const FadeIn = ({ children, index }) => {
  const [ref, inView] = useInView();
  const controls = useAnimation();
  const component_variants = {
    visible: {
      opacity: 1,
      translateY: 0,
      transition: { duration: 0.5, delay: index * 0.1 },
    },
    hidden: { opacity: 0, translateY: 10 },
  };
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={component_variants}
    >
      {children}
    </motion.div>
  );
};

export default FadeIn;
