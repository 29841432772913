import React, { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import ImageCropModal from "./ImageCropModal";

const ProductImage = ({ index, files, setFiles, res_resultImage = null }) => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [resultFile, setResultFile] = useState(null);

  function selectFile(e) {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setFileType(file.type);
      setFile(file);
      setShowModal(true);
      document.body.style.overflow = "hidden";
      e.target.value = null;
    } else {
      e.target.value = null;
      setShowModal(false);
      document.body.style.overflow = "auto";
    }
  }

  function exitModal() {
    setShowModal(false);
    document.body.style.overflow = "auto";
    setFile(null);
  }

  useEffect(() => {
    if (resultFile) {
      setFiles((prev) => {
        const newFiles = [...prev];
        newFiles[index].file = resultFile;
        newFiles[index].fileType = fileType;
        return newFiles;
      });
    }
  }, [resultFile]);

  return (
    <div>
      {resultImage || res_resultImage ? (
        <label
          htmlFor={"product-graphics-" + String(index)}
          className="w-40 block relative text-white h-40 bg-backgroundGray group cursor-pointer"
        >
          <img
            src={resultImage ? resultImage : res_resultImage}
            className="w-full"
          />
          <div className="absolute top-0 left-0 right-0 cursor-pointer transition-colors ease-in-out duration-300 bottom-0 group-hover:bg-black/25 bg-transparent"></div>
          <FlipCameraAndroidIcon className="w-10 text-white absolute top-1/2 translate-y-[-50%] left-1/2 translate-x-[-50%] group-hover:scale-105 ease-in-out duration-300 cursor-pointer transition opacity-0 group-hover:opacity-100 scale-150" />
          <input
            ref={file}
            onChange={selectFile}
            id={"product-graphics-" + String(index)}
            type="file"
            className="hidden"
          />
        </label>
      ) : (
        <label className="w-40 block relative h-40 bg-backgroundGray group cursor-pointer">
          <span className="group-hover:scale-150 block ease-in-out duration-300 transition-all absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2  scale-125">
            <AddIcon />
          </span>
          <input
            ref={file}
            onChange={selectFile}
            type="file"
            className="hidden"
          />
        </label>
      )}
      <ImageCropModal
        showModal={showModal}
        exitModal={exitModal}
        file={file}
        setResultFile={setResultFile}
        setResultImage={setResultImage}
      />
    </div>
  );
};

export default ProductImage;
